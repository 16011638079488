.card-combination{
    width: min-content;
    min-width: 20rem;

    &__banner{
        margin-inline: auto;
    }

    &__iframe-container{
        position: relative;
        overflow: hidden;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }

    // Contenedor botones
    &__actions{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;

        .btn{
            width: calc(50% - 1rem / 2);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
        }
    }

    // Código iframe
    pre{
        padding: 1rem;
        white-space: normal;
        color: var(--bs-info);
        background-color: var(--bs-dark);
    }
}