.header{
    // Logo y Nombre App
    .navbar-brand{
        font-family: 'Roboto', sans-serif;
        display: flex;
        align-items: center;
        gap: 1rem;

        // Logo Ariadna
        img{
            max-width: 10rem;
        }

        // Nombre App
        // &__text{
        // } 
    }

    // Contenedor nombre compañia y logo
    &__right{
        display: flex;
        gap: 1rem;
        color: $color-neutral--white;
        align-items: center;
    }

    // Dropdown
    &__avatar{
        .dropdown-toggle{
            min-width: 3.9rem;
            // Imagen
            img{
                max-width: 4rem;
            }

            // Flecha abajo
            &::after{
                content: unset;
                display: none;
            }
        }

        .dropdown-item:hover {
            color: var(--bs-dropdown-link-active-color);
            text-decoration: none;
            background-color: var(--bs-dropdown-link-active-bg);
        }

        // Formulario Logout
        #logout-form{
            button{
                color: inherit;
            }
        }
    }
}