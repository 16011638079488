//---------------------------------------------------------------------
// ESTILOS GENERALES
//---------------------------------------------------------------------

html {
	scroll-behavior: smooth;
	height: 100%;
	font-size: 62.5%;
	// font-size: 10px;
}

// :root {
// 	--bs-body-font-size: 1.6rem;  /* Overwrite this variable */
// 	font-size: var(--bs-body-font-size);
// }

body {
	font-size: 1.6rem;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	scroll-behavior: smooth;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom; //quita el margen raro abajo de la img
}

.main-content{
	margin-top: $header-height;
}

.padding-y{
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.bg-purple-gradient{
	background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(100,43,115,1) 0%, rgba(4,0,4,1) 90% );
}

.fullheight-section{
	min-height: calc(var(--vh, 1vh) * 100 - #{$header-height} - #{$footer-height});
}