/* ==================== BUTTONS =================== */

// Sass Placeholder
// https://medium.com/stories-from-the-keen/when-to-use-extends-vs-mixins-in-sass-b09d55abd53
// https://sass-lang.com/documentation/style-rules/placeholder-selectors/

%reset-btn {
    // Reset styles
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: none;
    background-color: transparent;
    text-decoration: none;
    padding: 0;
}

.button {
    &--nostyles {
        @extend %reset-btn;
    }
}

// Botonmes Bootstrap
.btn{
    font-size: 1.6rem;
}