.banner {
    $txt-padding-Y: 3rem;
    $txt-padding-X: 5rem;

    // Contenedor items
    &__wrapper {
        position: relative;

        &.owl-carousel {

            .owl-nav,
            .owl-dots {
                // Estilos para contenedor centrado
                @include bs-container;
                position: absolute;
                left: 0;
                right: 0;

                &.disabled {
                    display: none;
                }
            }

            // Navegación flechas carousel
            .owl-nav {
                top: 50%;
                transform: translateY(-50%);

                button {
                    position: absolute;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    transition: all 300ms;
                    opacity: 0.7;

                    @include media-query(mindesktop) {
                        bottom: 0;
                    }

                    @include media-query(mindesktopL) {
                        top: 50%;
                        transform: translateY(-50%);
                        bottom: unset;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    &.owl-prev,
                    &.owl-next {
                        //   background-size: auto;
                        background-size: 0.8rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        display: block;
                        outline: none;

                        span {
                            display: none;
                            font-size: 2.5rem;
                            content: "";
                            font-size: 2.5rem;
                        }
                    }

                    &.owl-prev {
                        left: 0;
                        background-image: url("./assets/images/left-arrow.svg");
                    }

                    &.owl-next {
                        right: 0;
                        background-image: url("./assets/images/right-arrow.svg");
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            // Navegación bullets
            .owl-dots {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                position: absolute;
                bottom: 1.5rem;

                button {
                    color: inherit;
                    border: none;
                    padding: 0 !important;
                    font: inherit;
                    width: 5rem;
                    height: 0.7rem;
                    border-radius: 0.6rem;
                    transition: all 300ms;
                    opacity: 0.7;

                    &.active {
                        opacity: 1;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }

    // Item
    &__item {
        // display: block;
        // width: 100vw;
        // height: 100vh;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        max-width: 100vw;
        display: grid;
        grid-template-areas: "stack";
        min-width: calc(var(--vw, 1vw) * 100);
        container: banneritem / size;

        // Para quitarle estilos de enlace
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }

        &.banner-card{
            height: 100%;
            min-width: unset;


            // .banner__title,
            // .banner__desc {

            //     @include media-query(mindesktop) {
            //         width: auto;
            //         max-width: 80%;
            //     }
            // }
        }

        // Apilamos todos los hijos
        & > * {
            grid-area: stack;
            min-width: 0;
            min-height: 0;
        }
    }

    &__video {
        // min-width: 100%;
        // min-height: 100%;
        // position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        aspect-ratio: 16/9;

        @media (min-width: 768px) and (orientation: landscape) {
            object-fit: cover;
        }
        
        @include media-query(mindesktop) {
            object-fit: cover;
        }

    }

    // Imagen fondo
    &__bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    // Contenedor central
    &__container{
        // position: absolute;
        // top: 0;
        // left: 0;
        // bottom: 0;
        // right: 0;
        // Estilos bootstrap container
        @include bs-container;
        width: 100%;
        height: 100%;
    }

    // Contenedor titulo y desc
    &__txt {
        // position: absolute;
        // top: 0;
        // left: 0;
        // bottom: 0;
        // right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // padding: $txt-padding-Y;
        padding: 2cqw;

        @include media-query(mindesktop) {
            // padding: $txt-padding-Y $txt-padding-X;
            padding: 2cqh 3cqw;
        }

        // Posiciones Verticales

        // Top
        &.jct{
            justify-content: flex-start;
        }

        // Center
        &.jcc{
            justify-content: center;
        }

        // End
        &.jcb{
            justify-content: flex-end;
        }

        // Posiciones Horizontales
        
        // left
        &.ail{
            align-items: flex-start;
        }

        // center
        &.aic{
            align-items: center;
        }

        // right
        &.air{
            align-items: flex-end;
        }


    }

    &__title,
    &__desc {
        color: inherit;
        width: 100%;

        // @include media-query(mindesktop) {
        //     // width: calc(50% - #{$txt-padding-X});
        //     // max-width: calc(50% - #{$txt-padding-X});
        //     width: calc(50% - 3cqw);
        //     max-width: calc(50% - 3cqw);
        // }
    }

    // Titulo
    &__title {
        margin-bottom: 1cqh; //0.7em
        font-weight: $weight-bold;
        line-height: 1.4;
    }

    // Descripción
    &__desc {
        // font-size: 1.8rem;
        margin: 0;
        line-height: 1.4;
    }

}

// Posición izquierda
.asl {
    align-self: flex-start;
}

// Posición central
.asc {
    align-self: center;
}

// Posición derecha
.asr {
    align-self: flex-end;
}

@container (min-width: 992px) {
    .banner__title,
    .banner__desc {
        width: calc(50% - 3cqw);
        max-width: calc(50% - 3cqw);
    }
}