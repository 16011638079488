.hero{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;

    @include media-query(mindesktop) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    &__title{
        font-size: 4vw;
    }

    &__badge{
        margin-top: 2.5rem;
        font-size: 2rem;
    }

}