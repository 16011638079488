// Contenedor input y label
.form-row{
    display: flex;
    flex-direction: column;
}

// Contenedor botones submit
.form-buttons{
    display: flex;
    justify-content: space-between;
}